/* eslint-disable indent */
/* eslint-disable one-var */
/* eslint-disable no-use-before-define */
/* eslint-disable new-cap */
import axios from 'axios';
import { ElNotification } from 'element-plus';
import store from '../store';
import { removeUser } from '@/utils/auth';
import router from '../router';

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // api 的 base_url
  timeout: 50000 // 请求超时时间
});
// request拦截器

service.interceptors.request.use(
  config => {
    const token = store.getters.token;

    if (token) {
      config.headers['Authorization'] = token;
      if (config.method === 'POST' || config.method === 'post') {
        config.headers['Content-Type'] = 'application/json;charset=utf-8';
      }
    }
    // 时间戳 解决ie下缓存问题
    config.params = {
      _t: Date.parse(new Date()) / 1000,
      ...config.params
    };
    return config;
  },
  error => {
    Promise.reject(error);
  }
);
// response 拦截器
service.interceptors.response.use(response => {
  const res = response.data;

  console.log('response 拦截器', res);
  if (response.request.responseType === 'blob') {
    return response.data;
  } else if (res.success) {
    // if (res.code === 200) {
    //   ElNotification({ message: res.msg, type: 'success', duration: 500 });
    // }
    return response.data;
  } else if (res.success === false) {
    if (res.code === 2000) {
      ElNotification({ message: res.msg ? res.msg : '系统警告', type: 'warning', duration: 2000 });
    } else {
      ElNotification({ message: res.msg ? res.msg : '系统错误', type: 'error', duration: 2000 });
    }
    return response.data;
  }
},
  error => {
    console.log('response error', error);
    const code = error.response.status;

    if (code === 401) {
      removeUser();
      store.dispatch('user/updateUserInfo', null);
      router.push('/login');
    } else if (error.response.data) {
      ElNotification.error({ title: '温馨提示', message: error.response.data.msg, duration: 2000 });
    } else {
      ElNotification.error({ title: '系统异常', message: '请求错误,请联系管理员', duration: 2000 });
      return { msg: '系统异常,请联系管理员', code: '500', success: false };
    }
    const res = error.response.data;


    return res;
  }
);
export function downFile (blob, fileName) {
  // 非IE下载
  if ('download' in document.createElement('a')) {
    const link = document.createElement('a');

    link.href = window.URL.createObjectURL(blob); // 创建下载的链接
    link.download = fileName; // 下载后文件名
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click(); // 点击下载
    window.URL.revokeObjectURL(link.href); // 释放掉blob对象
    document.body.removeChild(link); // 下载完成移除元素
  } else {
    // IE10+下载
    window.navigator.msSaveBlob(blob, fileName);
  }
}
export default service;
