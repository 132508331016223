import request from '@/utils/request';
/** 分页查询 */
export function dictionaryPageInfo (data) {
  return request({
    url: `/auth-server/sys/dictionary/page`,
    method: 'post',
    data: data
  });
}
/** 查询列表 */
export function dictionaryQueryList (data) {
  return request({
    url: `/auth-server/sys/dictionary/queryList`,
    method: 'post',
    data: data
  });
}
/** 保存 */
export function dictionarySave (data) {
  return request({
    url: `/auth-server/sys/dictionary/save`,
    method: 'post',
    data: data
  });
}

/** 删除 */
export function dictionaryDelete (id) {
  return request({
    url: `/auth-server/sys/dictionary/delete/${id}`,
    method: 'get'
  });
}
